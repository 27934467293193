<template>
    <section>
        <div class="row">
            <div class="col-xxl-9 col-xl-9 col-lg-9 col-md-12 col-sm-12 col-12 d-flex mb-3">
                <div class="table-box p-3">

                    <div class="d-flex">
                        <div class="flex-grow-1">
                            <router-link v-if="data.permission.canCreate"
                                            :to="'/library/create-template'" class="btn btn-primary" type="button">
                                Create New
                            </router-link>
                        </div>
                        <div class="expand-ctl">
                            <a class="btn btn-primary" type="button" data-bs-toggle="collapse"
                                data-bs-target="#collapseProject-ctl"
                                aria-expanded="false" aria-controls="collapseProject-ctl">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                                        class="bi bi-funnel-fill" viewBox="0 0 16 16">
                                    <path
                                        d="M1.5 1.5A.5.5 0 0 1 2 1h12a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-.128.334L10 8.692V13.5a.5.5 0 0 1-.342.474l-3 1A.5.5 0 0 1 6 14.5V8.692L1.628 3.834A.5.5 0 0 1 1.5 3.5v-2z"/>
                                </svg>
                            </a>
                        </div>
                    </div>
                    <div class="collapse mt-3" id="collapseProject-ctl">
                        <div class="row">
                            <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                                <div class="input-group search-box">
                                    <input type="text" class="form-control" placeholder="Search" name="search" v-model="setParams.search" @keyup.enter="searchList">
                                    <button class="btn btn-outline-secondary" type="button" @click="searchList">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-search" viewBox="0 0 16 16">
                                            <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z"></path>
                                        </svg>
                                    </button>
                                </div>
                                <div class="input-group row">
                                    <div class="col-12 col-sm-12 col-md-4 col-lg-4 mb-3">
                                        <label class="form-control-label">Select Document</label>
                                        <select name="doc-selection" id="doc-selection" class="form-control form-control-sm" v-model="setParams.docSelection" @change="searchList">
                                            <option value="">Select Document</option>
                                            <option
                                                v-for="(doc, index) in userData.docList"
                                                :key="`assinged-person-${index}`"
                                                :value="doc.id"
                                            >
                                                {{ doc.name }}
                                            </option>
                                        </select>
                                    </div>
                                    <div class="col-12 col-sm-12 col-md-4 col-lg-4 mb-3">
                                        <label class="form-control-label">Select Status</label>
                                        <select name="status-selection" id="status-selection" class="form-control form-control-sm" v-model="setParams.statusSelection" @change="searchList">
                                            <option value="">Select Status</option>
                                            <option value="Assigned">Assigned</option>
                                            <option value="Approve">Approved</option>
                                            <option value="Draft">Draft</option>
                                        </select>
                                    </div>
                                    <div class="col-12 col-sm-12 col-md-4 col-lg-4 mb-3">
                                        <label class="form-control-label">Select Assigned User</label>
                                        <select name="status-selection" id="status-selection" class="form-control form-control-sm" v-model="setParams.assignedSelection" @change="searchList">
                                            <option value="">Select Assigned</option>
                                            <option
                                                v-for="(user, index) in userData.userList"
                                                :key="`assinged-person-${index}`"
                                                :value="user.id"
                                            >
                                                {{ user.name }}
                                            </option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="d-flex">
                        <div class="flex-grow-1">
                            <h2 class=" mb-1">Project Content Items</h2>
                        </div>
                    </div>

                    <data-table
                        id="example-data-table"
                        :options="options"
                        :data-set="data.dataList"
                        :page-size="data.pageSize"
                        @change-action="changeAction"
                        @change-status="changeStatus"
                        @pagination="pagination"
                        @sorting="sorting"
                        @limit-change="limitChange"
                    />
                </div>
            </div>
            <div
                    class="
          col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12
          d-flex
          mb-3
        "
            >
                <DxSankey
                    id="sankey"
                    :data-source="data.sancyData"
                    source-field="source"
                    target-field="target"
                    weight-field="weight"
                    title="Content Item Flow on Milestones"
                >
                    <DxSize width: 100>
                        <DxTooltip
                            :enabled="true"
                            :customize-link-tooltip="customizeLinkTooltip"
                        />
                        <DxNode :width="4" :padding="10"/>
                        <DxLink color-mode="gradient"/>
                    </DxSize>
                </DxSankey>
            </div>
        </div>
    </section>
</template>

<script>
    import DxSankey, {
        DxLink,
        DxNode,
        DxTooltip,
        DxSize,
    } from "devextreme-vue/sankey";
    import HelperFunction from "@/common/helpers";
    import DataTable from "@/common/components/datatable";
    import {useRoute, useRouter} from "vue-router";
    import {useStore} from "vuex";
    import {onMounted, watch, inject, reactive, computed, ref} from "vue";

    export default {
        name: "ProjectContentItems",
        components: {
            DxSankey,
            DxTooltip,
            DxNode,
            DxLink,
            DxSize,
            DataTable,
        },
        setup() {
            const {data, getAllData, permissionResolver, storeFormData} = HelperFunction();
            const store = useStore();
            const company = computed(() => store.getters.getSelectedBoard);
            const route = useRoute();
            const router = useRouter();
            const swal = inject('$swal');
            const projectStatus = computed(() => store.getters.getProjectStatus)

            let setParams = {
                company_id: company.value.id,
                project_id: route.params.id,
                sort: "asc",
            };

            let userData = reactive({
                userList: [],
                docList: [],
            });

            let haveEditPermission = ref(false)

            data.setURL = vueConfig.Project.ContentItemEndPoint;
            data.sancyData = [];

            userData.userList = [];
            userData.docList = [];

            

            onMounted(async () => {
                getAllData(data.setURL, setParams);

                await getAllData(
                    vueConfig.Project.SankeyDataEndPoint + "/" + route.params.id
                ).then((resp) => {
                    data.sancyData = resp.data.data;
                });

                // URL for getting users
                let projectsProjectApproverUserUrl = `${vueConfig.Project.ProjectsProjectApprovers}?project_id=${setParams.project_id}&company_id=${setParams.company_id}`;
                getAllData(projectsProjectApproverUserUrl).then((resp) => {
                    let result = resp.data.data;
                    if (result.hasOwnProperty('assignedUser')) {
                        userData.userList = result.assignedUser;
                    } else {
                        userData.userList = [];
                    }
                });

                // URL for getting project documents
                let projectsDocsUrl = `${vueConfig.Project.Documents}?project_id=${setParams.project_id}&company_id=${setParams.company_id}`;
                getAllData(projectsDocsUrl).then((resp) => {
                    let result = resp.data.data.results;
                    userData.docList = result;
                });
            });

            // SET DATATABLE PROPS
            let options = {
                name: "",
                columns: [
                    {
                        title: "Milestone",
                        type: "text",
                        key: "milestone_names",
                        sort: false,
                    },
                    {
                        title: "Content Item",
                        type: "link",
                        key: "content_item_identifier",
                        sort: true,
                        truncate: true,
                        modifier: (obj, row) => {
                            return `/projects/${route.params.id}/project-task/${row.id}`;
                        },
                    },
                    {
                        title: "Version",
                        type: "text",
                        key: "version_no",
                        sort: true,                        
                    },
                    {
                        title: "Status",
                        type: "text",
                        key: "status",
                        sort: true,
                    },
                    {
                        title: "Deadline",
                        type: "text",
                        key: "milestone_dates",
                        sort: false,
                    },
                    {
                        title: "Tags",
                        type: "text",
                        key: "tags",
                        sort: false,
                    },
                    {
                        title: "Assigned",
                        type: "text",
                        key: "author_name",
                        sort: true,
                    },
                    {
                        title: "Days Left",
                        type: "text",
                        key: "expire_date",
                        sort: true,
                    },
                    // {
                    //     title: "Last Modified",
                    //     type: "text",
                    //     key: "created_at",
                    //     sort: true,
                    // },
                ],
                showAction: true,
                action: [
                    {
                        title: "Re Open",
                        icon: "lock",
                        type: "re-open",
                        show: haveEditPermission,
                        modifier: (action, row) => {
                            return row.status === 'Approve';
                        },
                    },
                    {
                        title: "Edit",
                        icon: "edit",
                        type: "edit",
                        show: false,
                    },
                    {
                        title: "Delete",
                        icon: "trash-alt",
                        type: "delete",
                        show: false,
                    },
                ],
            };

            /** watch **/
            permissionResolver('contents');

            watch(() => projectStatus.value, (value) => {
                if(value && value == 'Completed')
                {
                    options.showAction = false
                }
            }, {immediate: true});
            
            watch(() => data.permission.canEdit, (value) => {                
                if(value)
                {
                    haveEditPermission.value = value
                }
            }, {immediate: true});
            /** watch end **/

            async function changeAction(action, row) {
                if (action.type === "edit") {
                    await editFormData(
                        {url: `${data.setURL}/${row.id}`, modalId: "showModal"},
                        false,
                        setFormData
                    );
                }
                else if (action.type === 're-open') {
                    reOpen(row);
                }
                else if (action.type === "delete") {
                    deleteListData(`${data.setURL}/${row.id}`, setParams);
                }
            }

            async function reOpen(row) {
                const { value: rational_content } = await swal.fire({
                    input: 'textarea',
                    inputLabel: 'Rational Content',
                    inputPlaceholder: 'Type your rational content here...',
                    inputAttributes: {
                        'aria-label': 'Type your rational content here'
                    },
                    showCancelButton: true
                });

                if (rational_content) {
                    let content_id = row.id;

                    let payload = {
                        url: vueConfig.Project.ContentItemsReOpenEndPont + '/' + content_id,
                        params: {
                            content: 'rational_content'
                        }
                    }

                    await storeFormData(payload, payload.params).then((response) => {
                        let content_item_id = response.data.data.id;
                        router.push(`/projects/${setParams.project_id}/project-task/${content_item_id}`);
                    });
                }
            }

            const changeStatus = (column, row) => {
                let input = {};
                if (row.id) {
                    input._method = "PUT";
                }
                storeFormData(
                    {url: `${vueConfig.Project.ChangeStatusEndPoint}/${row.id}`},
                    input,
                    false,
                    setParams
                );
            };
            const pagination = (page) => {
                setParams.page = page;
                getAllData(data.setURL, setParams);
            };
            const limitChange = (limit) => {
                setParams.limit = limit;
                delete setParams.page
                getAllData(data.setURL, setParams);
            };
            const sorting = (column) => {
                setParams.column = column.key;
                setParams.sort = setParams.sort == "asc" ? "desc" : "asc";
                getAllData(data.setURL, setParams);
            };

            function setFormData(formData) {
                //console.error(formData)
                data.inputFormData.project_id = formData.project_id
                    ? formData.project_id
                    : "";
            }

            function searchList() {
                setParams.search = setParams.search == '' ? '' : setParams.search;
                setParams.statusSelection = setParams.statusSelection == '' ? '' : setParams.statusSelection;
                setParams.assignedSelection = setParams.assignedSelection == '' ? '' : setParams.assignedSelection;
                setParams.docSelection = setParams.docSelection == '' ? '' : setParams.docSelection;
                getAllData(data.setURL, setParams);
            }

            return {
                data,
                options,
                changeAction,
                changeStatus,
                pagination,
                limitChange,
                sorting,
                setParams,
                searchList,
                userData,
                projectStatus
            };
        },
    };
</script>

<style scoped>
    #sankey {
        width: 100%;
        height: 100%;
    }
</style>
